import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

import Layout from "@/components/Layout";
import checkIfFullFridge from "@/utils/fullFridge";
import Home from "@/components/Home";
import Mobile from "@/components/Mobile";

const Index = () => (
  <Layout hideFunctionality>
    <Head>
      <title>
        {checkIfFullFridge()
          ? "FullFridge.app - Dzisiaj każdy może mieć swoją wirtualną lodówkę"
          : "GarazoweWyprzedaze.pl - Dzisiaj każdy może mieć swój wirtualny garaż"}
      </title>
    </Head>
    {/* <Mobile /> */}
    <Home />
  </Layout>
);

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default Index;
